import styled from 'styled-components';

export const Tile = styled.div`
    display: flex;
    align-items: center;

    flex-direction: column;
    text-align: center;
`;

export const ImageContainer = styled.div`
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    margin-bottom: 20px;

    span {
        top: 12px;
        left: 12px;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: url('/assets/bg/icon-bow.svg') center no-repeat;
        background-size: contain;
    }
`;
