import breakpoints from 'constants/breakpoints';

import styled from 'styled-components';

export const Container = styled.div`
    margin: 100px 0 150px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    overflow: hidden;
    position: relative;
    border-radius: 30px;

    --margin-desktop: 100px;
    --margin-tablet: 20px;

    background: linear-gradient(180deg, #1b1b4940 0%, rgba(27, 27, 73, 0) 100%);

    ${breakpoints.abovePhone} {
        margin-left: var(--margin-tablet);
        margin-right: var(--margin-tablet);
    }

    ${breakpoints.aboveTablet} {
        margin: 100px 0 164px;
        margin-left: var(--margin-desktop);
        margin-right: var(--margin-desktop);
    }
`;

export const TilesContainer = styled.div`
    display: grid;
    justify-content: space-around;
    list-style-type: none;
    padding: 0 30px;
    width: 100%;
    max-width: 1200px;
    gap: 60px 45px;

    ${breakpoints.abovePhone} {
        grid-template-columns: repeat(3, minmax(200px, 280px));
    }

    ${breakpoints.aboveTablet} {
        gap: 60px 75px;
    }
`;
