import Image from 'next/image';

import {ImageDataType} from '~utils/imageData';
import Text from '~components/atoms/Text';

import {ImageContainer, Tile} from './styles';

export type BlockProps = {
    title: string;
    description: string;
    icon: ImageDataType;
};

const IconTile = ({title, description, icon}: BlockProps) => {
    return (
        <Tile>
            <ImageContainer>
                <Image {...icon} width={32} height={32} layout="fixed" />
            </ImageContainer>
            <Text type="sectionName" color="white">
                {title}
            </Text>
            <Text type="content" color="white">
                {description}
            </Text>
        </Tile>
    );
};

export default IconTile;
