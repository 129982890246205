import {getImageData} from '~utils/imageData';
import Text from '~components/atoms/Text';
import TextContainer from '~components/atoms/TextContainer';
import IconTile from '~components/molecules/IconTile';
import {PageQueryPageDataAttributesContentComponentSectionTheGateway} from '~interfaces/graphql/PageQuery';

import {Container, TilesContainer} from './styles';

type TheGatewaySectionProps = {
    data: PageQueryPageDataAttributesContentComponentSectionTheGateway;
};
const TheGatewaySection = ({data}: TheGatewaySectionProps) => {
    return (
        <Container id={data.sectionId}>
            <TextContainer size="small">
                <Text type="sectionName" color="pink">
                    {data.sectionTitle}
                </Text>
                <Text type="sectionTitle">{data.sectionName}</Text>
                <Text type="content">{data.description}</Text>
            </TextContainer>
            <TilesContainer>
                {data.blocks.map((item, index) => (
                    <IconTile
                        {...item}
                        icon={getImageData(item.icon)}
                        key={`icon_tile_${index}`}
                    />
                ))}
            </TilesContainer>
        </Container>
    );
};

export default TheGatewaySection;
